import React, { useState, createContext, useContext } from "react";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";

type ThemePreference = "dark" | "light";
const ThemeContext = createContext<
    | {
          theme: ThemePreference;
          setTheme: (selection: ThemePreference | "auto") => void;
      }
    | undefined
>(undefined);

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error("useThemeContext must be used within a ThemeProvider");
    }
    return context;
};

const isValidTheme = (theme: string): theme is ThemePreference => {
    return ["dark", "light"].includes(theme);
};
export const ThemeProvider = ({ children }) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
        noSsr: true
    });

    const [currentTheme, setCurrentTheme] = useState<ThemePreference>(() => {
        const storedTheme = localStorage.getItem("reactTemplateTheme");
        if (storedTheme && isValidTheme(storedTheme)) {
            return storedTheme;
        }
        if (prefersDarkMode) {
            return "dark";
        }
        return "light";
    });

    function setTheme(selection: ThemePreference | "auto") {
        if (isValidTheme(selection)) {
            setCurrentTheme(selection);
            localStorage.setItem("reactTemplateTheme", selection);
        } else {
            const theme = prefersDarkMode ? "dark" : "light";
            setCurrentTheme(theme);
            localStorage.setItem("reactTemplateTheme", theme);
        }
    }
    const muiTheme = createTheme(
        adaptV4Theme({
            palette: {
                mode: currentTheme,
                primary: {
                    main: "#E61E28",
                    light: "#FF6A6A",
                    dark: "#A60606",
                    contrastText: "#ffffff"
                },
                secondary: {
                    main: "#28AF73",
                    light: "#BAFFB9",
                    dark: "#056937",
                    contrastText: "#ffffff"
                },
                background: {
                    default: currentTheme === "light" ? "#ffffff" : "#303030",
                    paper: currentTheme === "light" ? "#EFEFEF" : "#424242"
                }
            },
            typography: {
                fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                fontWeightLight: 300,
                fontWeightRegular: 400,
                fontWeightBold: 700,
                fontSize: 16,
                caption: {
                    fontSize: 16,
                    fontWeight: 400
                },
                h1: {
                    fontSize: 128
                },
                h2: {
                    fontSize: 80
                },
                h3: {
                    fontSize: 64
                },
                h4: {
                    fontSize: 44.8
                },
                h5: {
                    fontSize: 32
                },
                h6: {
                    fontSize: 20
                },
                button: {
                    fontSize: 16,
                    textTransform: "capitalize"
                }
            }
        })
    );
    return (
        <ThemeContext.Provider value={{ theme: currentTheme, setTheme }}>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
            </StyledEngineProvider>
        </ThemeContext.Provider>
    );
};
