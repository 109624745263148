import { useState } from "react";
import Chat from "../chat/Chat";
import { ThemeProvider } from "../../context/theme";
import styles from "./Layout.module.css";
import { useMsal, useMsalAuthentication, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { ArcContainer } from "@arc-web/components/dist/components/container/react";
import { useThemeContext } from "../../context/theme";
import { Theme, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "SignIn";
const classes = {
    root: `${PREFIX}-root`,
    loginBtn: `${PREFIX}-loginBtn`,
    helpButton: `${PREFIX}-helpButton`
};

const Root = styled("div")(({ theme }: { theme: Theme }) => ({
    [`&.${classes.root}`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: "1",
        height: "50vh",
        borderTop: `1px solid ${theme.palette.background.paper}`
    },

    [`& .${classes.loginBtn}`]: {
        border: `1px solid ${theme.palette.primary.main}`,
        padding: "0.25rem 3rem",
        marginBottom: "0.5rem",
        "&:hover": {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    },

    [`& .${classes.helpButton}`]: {
        "&:hover": {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            background: "none"
        },

        borderBottom: " 1px solid transparent",
        transition: "all .25s ease-in-out",
        "& p": {
            fontSize: "0.8rem"
        }
    }
}));

// SignInButton Component returns a button that invokes a popup login when clicked
function SignInButton() {
    const [isLoading, setIsLoading] = useState(false);
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();
    const { setTheme } = useThemeContext();

    async function signInClickHandler(instance: IPublicClientApplication) {
        setIsLoading(true);
        await instance.loginPopup();
        setIsLoading(false);
    }

    return (
        <ArcContainer
            onArcAccessibilityChange={event => {
                setTheme(event.detail.preferences.theme);
            }}
        >
            <Root className={classes.root}>
                <Button onClick={() => signInClickHandler(instance)} className={classes.loginBtn} color="primary" disabled={isLoading}>
                    Sign In
                </Button>
            </Root>
        </ArcContainer>
    );
}

const Layout = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <div className={styles.layout}>
                    <Chat />
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className={styles.layout}>
                    <ThemeProvider>
                        <SignInButton />
                    </ThemeProvider>
                </div>
            </UnauthenticatedTemplate>
        </>
    );
};

export default Layout;
