/**
 * Given a citation for a specific page, this function returns the path to the whole document pointing to that page.
 * @param citation The citation for a specific page. For example, "M5-document-5.pdf".
 * @returns The path to the whole document pointing to that page. For example, "/content/M5-document.pdf".
 */
export function getParentCitationFilePath(citation: string): string {
    const regexAll = /^([^\\]*)\.(\w+)$/;
    const [_, title, extension] = citation.match(regexAll) || [];
    const pageNumber: string = title?.split("-").pop() || "";
    const titleMinusPageNumber = title?.slice(0, -pageNumber.length - 1) || "";
    const result = `/content/${titleMinusPageNumber}.${extension}#page=${pageNumber}`;
    return result;
}
